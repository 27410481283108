import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from 'prop-types';
import "./table.scss";
import { useTable } from "react-table";
import FixedColumns from "react-table-hoc-fixed-columns";
import SortArrows from "../SVG/SortArrows";
import DotMenu from "../DotMenu/DotMenu";
import ScholarshipNameCellType from "./CellTypes/ScholarshipNameCellType/ScholarshipNameCellType";
import LinkCellType from "./CellTypes/LinkCellType/LinkCellType";
import StatusCellType from "./CellTypes/StatusCellType/StatusCellType";
import ProgressCompleteCellType from "./CellTypes/ProgressCompleteCellType/ProgressCompleteCellType";
import DateCellType from "./CellTypes/DateCellType/DateCellType";
import PeopleCountCellType from "./CellTypes/PeopleCountCellType/PeopleCountCellType";
import PercentChangeCellType from "./CellTypes/PercentChangeCellType/PercentChangeCellType";
import TagCellType from "./CellTypes/TagCellType/TagCellType";
import ScoreCellType from "./CellTypes/ScoreCellType/ScoreCellType";
import NoDataToDisplay from "../NoDataToDisplay/NoDataToDisplay";
import KaleidoscopeAPI from "../../Core/KaleidoscopeAPI";
import FlashMessage from "../FlashMessage/Message";
import { ToastContainer, toast } from "react-toastify";
import { MAIN_CONFIG, IdentityVisibilityFields } from '../../../config/main';
import Loader, { TableListingLoader } from '../../Components/Loader/Loader';
import UserBadge from '../UserBadge/UserBadge';
import FormatDate, { formatDateByMoment } from "../../Utility/FormatDate";
import { isEmpty } from 'lodash'
import cookies from 'next-cookies';
import FieldCheckbox from '../../Components/SVG/FieldCheckbox';
import { useRouter } from 'next/router';
import Field from '../../Components/Field/Field';
import { CreateOptions } from '../../Utility/SelectListLoader';
import Verified from "../SVG/Verified"
import NotVerified from "../SVG/NotVerified"
import StatusCheckBox from "./CellTypes/StatusCheckBox/StatusCheckBox";
import { formatCurrency, handleRedirectHrefUrl, parseHtml } from "../../Utility/HelperFunctions";
import CheckCleared from "./CellTypes/CheckCleared/CheckCleared";

const API = new KaleidoscopeAPI({});

const Table = ({
  id,
  columnData,
  tableData,
  handleSort = () => { },
  currentSortColumn = "",
  currentSortDirection = "",
  setShowTableActionBar = () => { },
  setTableActionBarNumSelected = () => { },
  setTableActionBarNumTotal,
  getRowMenu = () => { },
  showSelect = true,
  getRowCta = () => { },
  showRowCta = false,
  isLoading = false,
  identityVisible = true,
  noDataDescription,
  noDataCtaLabel,
  noDataCtaOnClick,
  setSelectedRows = () => { },
  setRemoveRows = () => { },
  setSelectedRowsAll = () => { },
  setCurrentSearchTermsScholarship,
  setCurrentSearchKeyScholarship,
  accountData,
  selectedRows = "",
  selectedRowsAll = "",
  removeRows = "",
  currentTabTotalRecordCount = "",
  selectedRowsAllNewSelect = "",
  setSelectedRowsAllNewSelect = () => { },
  clickTableDataView = () => { },
  isPeopleShowRole = false,
  globeHide = false,
  selectAllRowPageOnly = false,
  parentId='',
  showResetPasswordBtn = true,
  handleResetPasswordClick = () => { },
  pageType = '',
  showBlankHeader,
  selectedRowsData = [],
  setSelectedRowsData = () => {},
}) => {
  const [selectedRowsNewSlect, setSelectedRowsNewSlect] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  // const [checkSelectedSort, setCheckSelectedSort] = useState("");
  const [removeRowsNewSelect, setRemoveRowsNewSelect] = useState([]);
  const [openDotMenu, setOpenDotMenu] = useState("");
  const router = useRouter();
  const tableRef = useRef(null);
  const checkSelectedSort = localStorage.getItem("checkSelectedSort")

  useEffect(() => {
    // Automatically scroll when dot menu is opened
    ensureDotMenuVisible();
  }, []); // This will run the code when the component mounts
  // Memoize is required by react-table for the table columns
  // TODO: Move this logic to separate component/method.
  const columns = useMemo(
    () =>
      columnData.table_headers.filter(header => identityVisible ? true : IdentityVisibilityFields.indexOf(header.field_api_name__c) === -1).map((item) => {
        const dataField = item.field_api_name__c
          ? item.field_api_name__c.toLowerCase()
          : item.column_name__c;

        return {
          Header: item.column_name__c,
          accessor: dataField,
          sortable: item.enable_sort__c,
          customClass: item.isCodeOnRideSide ? item.isCodeOnRideSide : "",
          tdCustomClass: item.tdCustomClass ? item.tdCustomClass : "date-cell-type",
          primaryColor: item.primaryColor ? item.primaryColor : "",
          filterable: item.enable_filter__c,
          searchable: item.enable_search__c,
          type: item.field_data_type__c,
          profileView: item.profile_view ? item.profile_view : false,
          porfilePic: item.porfile_pic ? item.porfile_pic : "",
          porfileFirstname: item.porfile_firstname ? item.porfile_firstname : "",
          porfileLastname: item.porfile_lastname ? item.porfile_lastname : "",
          porfileEmail: item.porfile_email ? item.porfile_email : "",

          sortDirection:
            currentSortColumn === dataField ? currentSortDirection : "",
        };
      }),
    [columnData]
  );

  // Memoize is required by react-table for the table data
  // TODO: Move this logic to separate component/method.
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  /**
   * Handles when a particular column is clicked for sorting.
   * @param {object} column
   */
  const handleSortClick = (column) => {
    handleSort(column.id, currentSortDirection, parentId);
    // setCheckSelectedSort(column.id);
    localStorage.setItem("checkSelectedSort" , column.id)
  
    // Store search items in sessionStorage
    const searchItems = {
      column: column.id,
      currentSortDirection: currentSortDirection == 'ASC' ? 'DESC' : 'ASC',
      parentId: parentId,
      lastSortPageType : pageType
    };
    const searchFilter = JSON.stringify(searchItems);
    sessionStorage.setItem("searchFilter", searchFilter);
    };
  

  useEffect(() => {
    const searchFilterString = sessionStorage.getItem("searchFilter");
    if (searchFilterString) {
      const searchFilters = JSON.parse(searchFilterString);
      const { column, currentSortDirection, parentId, lastSortPageType } = searchFilters;
      if (column && lastSortPageType == pageType && pageType !== 'ADMIN') {
        handleSort(column, currentSortDirection, lastSortPageType, parentId);
      }
    }
  }, []);
  
  const activeAutoScroll = cookies('context').context === MAIN_CONFIG.USER_CONTEXT.DONOR && pageType ==="APPLICATIONS"
  function ensureDotMenuVisible(recevedIndex) {
    const table = tableRef.current;

    if (!table && !activeAutoScroll) {
      // If the table or dot menu elements are not found, return
      return;
    }
    if (tableRef.current && rows?.length == recevedIndex) {
      tableRef.current.scrollTop = tableRef.current.scrollTop + 100 ;
    }
  }
  useEffect(() => {
    setOpenDotMenu("")
    if (selectedRowsAllNewSelect === "all") {
      setSelectedRowsNewSlect(rows.map((r, i) => r.original.sfid));
    }
    if (!selectedRows && !removeRows && !selectedRowsAll && !selectedRowsAllNewSelect) {
      setSelectedRowsNewSlect([])
      setRemoveRowsNewSelect([])
      setSelectedRowsAllNewSelect("")
      setShowTableActionBar(false)
    }
    if(selectAllRowPageOnly === "all_on_page"){
      setSelectedRowsNewSlect([])
      setRemoveRowsNewSelect([])
      setSelectedRowsAllNewSelect("")
      setShowTableActionBar(false)
    }
  }, [tableData])

  /**
   * Renders a column header with sort if present.
   * @param {object} column
   * @returns {JSX.Element}
   */

  const renderColumnHeader = (column) => {
    const { sortable, customClass } = column;
    var showSorted = false;
    if (checkSelectedSort === column.id) {
      showSorted = true;
    }
    // const showSorted = currentSortColumn === column.id;
    return (
      <span className={`table__column-header`} >
        {column.render("Header")}
        {sortable ? (
          <span tabIndex={0} onClick={() => handleSortClick(column)} className={`${sortable ? "sortable" : ""}`}>
            <SortArrows
              className="table__sort-arrow"
              direction={showSorted ? currentSortDirection : ""}
              ariaLabel="Short Arrow"
              ariaLabelDescription="Click here to Short row by ascending or descending order"
            />
          </span>
        ) : (
          ""
        )}
      </span>
    );
  };

  /**
   * Adds/removes all selectable items and updates their checked state.
   * @param {object} e
   */
  const handleAllSelect = (e) => {
    if (e.target.checked) {
      var getSfids = rows.map((r, i) => r.original.sfid);
      setAllSelected(true);
      setSelectedRowsNewSlect(rows.map((r, i) => r.original.sfid));
      if(!selectAllRowPageOnly){
        setSelectedRowsAll("all");
        setSelectedRowsAllNewSelect("all");
        setShowTableActionBar(currentTabTotalRecordCount > 0 ? true : false);
        setRemoveRowsNewSelect([])
        setTableActionBarNumSelected(currentTabTotalRecordCount); // TODO: Update this with the full total
        sessionStorage.setItem('HideDotManu', currentTabTotalRecordCount)
        localStorage.setItem("slectedScholoerships", JSON.stringify(getSfids));
        localStorage.setItem("slectedScholoershipsAll", "all");
      } else {
        setSelectedRowsAll("all");
        setSelectedRowsAllNewSelect("all_on_page");
        setShowTableActionBar(true);
        setRemoveRowsNewSelect([]);
        setTableActionBarNumSelected(getSfids.length);
        sessionStorage.setItem('HideDotManu', getSfids.length)
        localStorage.setItem("slectedScholoerships", JSON.stringify(getSfids));
      }
      
    } else {
      setAllSelected(false);
      setSelectedRows([]);
      setSelectedRowsData([]);
      setSelectedRowsAll([]);
      setShowTableActionBar(false);
      setSelectedRowsAllNewSelect("");
      setTableActionBarNumSelected(0);
      sessionStorage.setItem('HideDotManu', 0)
      setRemoveRowsNewSelect([]);
      setSelectedRowsNewSlect([]);
      localStorage.setItem("slectedScholoerships", []);
      localStorage.setItem("slectedScholoershipsAll", "");
    }
  };

  /**
   * Handles checking/unchecking single items.
   * @param {object} e
   * @param {index} rowIndex
   */
  const handleRowSelect = (e, rowIndex) => {
    var getsfid = rowIndex.original.sfid;
    let newSelectedRows;
    var removeSelectRow;
    let selectedData;
    if (selectedRowsAllNewSelect != "all") {
      if (e.target.checked) {
        newSelectedRows = [...selectedRowsNewSlect, getsfid];
        selectedData = [...selectedRowsData, rowIndex.original]
      } else {
        newSelectedRows = [
          ...selectedRowsNewSlect.filter((r) => r !== getsfid),
        ];
        selectedData = [...selectedRowsData.filter((r) => r.sfid !== getsfid)]
      }

      setSelectedRowsNewSlect(newSelectedRows);
      setShowTableActionBar(newSelectedRows.length > 0);
      setTableActionBarNumSelected(newSelectedRows.length);
      sessionStorage.setItem('HideDotManu', newSelectedRows.length)
      setSelectedRows(newSelectedRows);
      setSelectedRowsData(selectedData);
      localStorage.setItem(
        "slectedScholoerships",
        JSON.stringify(newSelectedRows)
      );
    } else {
      if (e.target.checked) {
        newSelectedRows = [...selectedRowsNewSlect, getsfid];
        removeSelectRow = [...removeRowsNewSelect.filter((r) => r !== getsfid)];
        selectedData = [...selectedRowsData, rowIndex.original]
      } else {
        newSelectedRows = [
          ...selectedRowsNewSlect.filter((r) => r !== getsfid),
        ];
        removeSelectRow = [...removeRowsNewSelect, getsfid];
        selectedData = [...selectedRowsData.filter((r) => r.sfid !== getsfid)]
      }
      var newNumCount = currentTabTotalRecordCount - removeSelectRow.length
      setSelectedRowsNewSlect(newSelectedRows);
      setShowTableActionBar(newSelectedRows.length > 0);
      setTableActionBarNumSelected(newNumCount);
      sessionStorage.setItem('HideDotManu', newNumCount)
      setSelectedRows(newSelectedRows);
      setSelectedRowsData(selectedData)
      setRemoveRows(removeSelectRow);
      setRemoveRowsNewSelect(removeSelectRow);
      localStorage.setItem("removeSelectRow", JSON.stringify(removeSelectRow));
    }
    // TODO: Find a more efficient way to handle thi
  };

  // get selected scholarships or remove after get dropbox code

  useEffect(() => {
    if (location.search) {
      var selectApplicantsId = localStorage.getItem("urlId") ? localStorage.getItem("urlId") : ""
      if (location.pathname.concat(location.search) === `/client/scholarships/${selectApplicantsId}${location.search}` || location.pathname.concat(location.search) === `/reviewer/scholarships/${selectApplicantsId}${location.search}`) {
        localStorage.removeItem("urlId");
      }
      if (selectApplicantsId) {
        handleRedirectHrefUrl(`${localStorage.localURL}${location.search}`);
        localStorage.removeItem("urlId");
      }

      if (!selectApplicantsId) {
        var getRemoveSelectRow = localStorage.getItem("removeSelectRow")
          ? JSON.parse(localStorage.getItem("removeSelectRow"))
          : [];
        var slectedScholoerships = localStorage.getItem("slectedScholoerships")
          ? JSON.parse(localStorage.getItem("slectedScholoerships"))
          : [];
        var slectedScholoershipsAll = localStorage.getItem(
          "slectedScholoershipsAll",
          ""
        )
          ? localStorage.getItem("slectedScholoershipsAll", "")
          : "";
        var currentSearchVal = localStorage.getItem("searchVal") ? localStorage.getItem("searchVal") : ""
        var currentSearchKey = localStorage.getItem("searchKey") ? JSON.parse(localStorage.getItem("searchKey")) : ""
        var applicantFilters = localStorage.getItem("applicantFilters") ? JSON.parse(localStorage.getItem("applicantFilters")) : ""
        var activeTabTable = localStorage.getItem("activeTabTable") ? localStorage.getItem("activeTabTable") : ""
        setSelectedRowsNewSlect(slectedScholoerships);
        setShowTableActionBar(slectedScholoerships.length > 0);
        setTableActionBarNumSelected(slectedScholoerships.length);
        sessionStorage.setItem('HideDotManu', slectedScholoerships.length)
        setSelectedRows(slectedScholoerships);
        setRemoveRows(getRemoveSelectRow);
        setRemoveRowsNewSelect(getRemoveSelectRow);
        getExportCSVORPDF(
          getRemoveSelectRow,
          slectedScholoerships,
          slectedScholoershipsAll,
          currentSearchKey,
          currentSearchVal,
          applicantFilters,
          activeTabTable
        );
      }

    }

  }, []);

  const getExportCSVORPDF = async (
    getRemoveSelectRow,
    slectedScholoerships,
    slectedScholoershipsAll,
    currentSearchKey,
    currentSearchVal,
    applicantFilters,
    activeTabTable
  ) => {

    if (location.search) {

      if (location.search.split("=")[0] === "?code") {
        var dropbox_token = location.search.split("=")[1];
        var result;

        // var localURL = localStorage.getItem("removeID") ? "http://localhost:3000" : localStorage.localURL
        var localURL = localStorage.getItem("removeID") ? MAIN_CONFIG.URLS.SERVER_URL : localStorage.localURL

        var type = JSON.parse(localStorage.getItem("sectedExportType"));

        let userContext = cookies('context').context
        let userAdminContext = cookies('context').admincontext
        if (userContext === MAIN_CONFIG.USER_CONTEXT.DONOR || userContext === MAIN_CONFIG.USER_CONTEXT.SUPERDONOR) {
          let data = {
            token: accountData.token,
            type,
            is_all_selected: slectedScholoershipsAll === "all" ? "true" : "false",
            search_keys: currentSearchKey,
            search_value: currentSearchVal
          }
          if (localStorage.exportType === MAIN_CONFIG.PAGE_TYPES.DONOR.SCHOLARSHIPS) {
            data.selected_scholarship_ids = slectedScholoershipsAll === "all" ? "" : slectedScholoerships,
              data.removed_scholarship_ids = slectedScholoershipsAll === "all" ? getRemoveSelectRow : ""
            data.accountId = accountData.currentAccount.sfid
            data.scholarship_status = activeTabTable
            data.userContext = userContext

            result = await API.addScholarshipExport(data)
          } else if (localStorage.exportType === MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICATIONS) {
            data.selected_application_ids = slectedScholoershipsAll === "all" ? "" : slectedScholoerships,
              data.removed_application_ids = slectedScholoershipsAll === "all" ? getRemoveSelectRow : ""
            data.accountId = accountData.currentAccount.sfid
            data.application_status = activeTabTable
            data.userContext = userContext
            data.scholarshipId = localStorage.getItem("scholarshipId") ? localStorage.getItem("scholarshipId") : ""

            result = await API.scholarshipApplicationExport(data)
          } else {
            data.selected_application_ids = slectedScholoershipsAll === "all" ? "" : slectedScholoerships,
              data.removed_application_ids = slectedScholoershipsAll === "all" ? getRemoveSelectRow : ""
            data.accountId = accountData.currentAccount.sfid
            data.applicant_filters = applicantFilters

            result = await API.addApplicantsExport(data)
          }
        }

        if (userContext === MAIN_CONFIG.USER_CONTEXT.REVIEWER) {
          let data = {
            token: accountData.token,
            type,
            is_all_selected: slectedScholoershipsAll === "all" ? "true" : "false",
            search_keys: currentSearchKey,
            search_value: currentSearchVal
          }
          if (localStorage.exportType !== MAIN_CONFIG.PAGE_TYPES.DONOR.HOME) {
            data.selected_application_ids = slectedScholoershipsAll === "all" ? "" : slectedScholoerships,
            data.removed_application_ids = slectedScholoershipsAll === "all" ? getRemoveSelectRow : ""
            data.accountId = accountData.currentAccount.sfid
            data.application_status = activeTabTable
            data.userContext = userContext
            data.scholarshipId = localStorage.getItem("scholarshipId") ? localStorage.getItem("scholarshipId") : ""

            result = await API.scholarshipApplicationExport(data)
          } else {

            data.selected_scholarship_ids = slectedScholoershipsAll === "all" ? "" : slectedScholoerships,
              data.removed_scholarship_ids = slectedScholoershipsAll === "all" ? getRemoveSelectRow : ""
            data.accountId = accountData.currentAccount.sfid
            data.userContext = userContext

            result = await API.addScholarshipExport(data)
          }
        }
        if (userAdminContext === MAIN_CONFIG.USER_CONTEXT.ADMIN && isEmpty(userContext)) {
          let admintoken = cookies('context').admintoken

          let data = {
            token: admintoken
          }
          result = await API.exportLoginHistory(data)
        }

        if (result.success) {
          toast.success(<FlashMessage message={result.message} />);
          router.push(localStorage.localURL, undefined, { shallow: true })
          // window.location.href = localStorage.localURL
        } else {
          toast.error(<FlashMessage message={result.message} type="error" />);
          router.push(localStorage.localURL, undefined, { shallow: true })
          // window.location.href = localStorage.localURL
        }
        localStorage.clear();
      }
    }
  };

  const getCellComponentSplitData = (data, field) => {
    let value = ''
    if (field) {
      const dataField = field.split(".")
      if (dataField.length > 0) {
        if (dataField.length === 2) {
          value = data[dataField[0]][dataField[1]]
        } else {
          value = data[dataField[0]]
        }
      }
    }
    return value
  }
  /**
   * Map cell components to cells based on the column type.
   * @param {object} cell
   * @returns {JSX.Element|*}
   */
  const getCellComponent = (cell) => {
    let userContext = cookies('context').context
    // TODO: Revisit this when better table data is available.
    const { column, value , row } = cell;
    const aiPlagarismHeader=["ai_content_detection__c","plagiarism_detection__c"]
    const renderedCell = cell.render("Cell");
    if (column.Header) {
      const columnName = column.Header.toLowerCase();
      const isCurrency = column.type==="Currency"
      const columnId = column.id.toLowerCase() || ''
      switch (true) {
        case isCurrency:
          if (columnId === "balance") {
            return value === 0
              ? '0'
              : value
              ? `$ ${formatCurrency(value)}`
              : "N/A";
          }
          return value ? `$ ${formatCurrency(value)}` :"NA"
        case columnName === "start date":
        case columnName === "end date":
          return renderedCell;
        case columnName === "event link":
          return <LinkCellType value={value} />;
        case columnName === "status":
          return <StatusCellType value={value} />;
        case columnName === "% complete":
        case columnId === "progress__c":
          return (userContext === 'REVIEWER') ? parseInt(value) + '%' :  value + '%';
        case aiPlagarismHeader?.includes(columnId):
          return  value + '%';  
        case columnId === "original_submitted_date__c":
        case columnId === "submitted_status_date__c":
          return value ? formatDateByMoment(value, "MM/DD/YYYY") : "NA";
        case columnName.includes("deadline"):
        case columnName.includes("date"):
        case columnName.includes("created on"): 
        case columnName.includes("expires on"):
          return <DateCellType value={value} />;
        case columnName.includes("scholarship name"):
          return <ScholarshipNameCellType value={value} globeHide={globeHide} />;
        case columnId === "number_of_submitted_applications__c" :
          return <PeopleCountCellType value={value} />;
        case columnName.includes("change"):
          return <PercentChangeCellType value={value} />;
        case columnName === "id":
        case columnName === "application":
          return <TagCellType value={value} />;
        case columnName === "score":
          return <ScoreCellType value={value} />;
        case columnName === "days remaining":
          return parseInt(value)
          case columnName === 'exit reason':
            return parseHtml(value)
        case columnName === "last seen":
          // return value.split("T")[0]+ " " + value.match(/\d\d:\d\d/)[0];
          return value ? FormatDate(value, 'MM/dd/yyyy t') : "NA"
        case columnName === 'verified':
          return <StatusCheckBox value={value} />
        case columnName === 'year 1 efc':
          let newvalue = parseInt(value)
          return newvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        case columnId === 'check_cleared__c':
        case column.type === "Checkbox":
          return <CheckCleared value={value} columnId={columnId} />;
        case columnName === "role":
          var tempOption = value.split(";")

          if (tempOption.length > 0 && isPeopleShowRole) {
            return (
              <div>{value}</div>
            )
          } else {
            return renderedCell;
          }


        // if (tempOption.length > 1 && isPeopleShowRole) {
        //   return (
        //     <Field
        //       className='people-tab-user-role'
        //       type='select'
        //       value={tempOption[0]}
        //       options={CreateOptions(tempOption)}
        //       placeholder={tempOption[0]}
        //     />
        //   )
        // } else if (isPeopleShowRole && tempOption.length < 2) {
        //   <Field
        //     className='people-tab-user-role'
        //     type='text'
        //     value={tempOption[0]}
        //     placeholder={tempOption[0]}
        //   />
        // }
        // else {
        //   debugger
        //   return renderedCell;
        // }


      }
    }

    return renderedCell;
  };

  const getCellComponentForDataType = (cell) => {
    // TODO: Revisit this when better table data is available.
    const { column, value } = cell;
    const renderedCell = cell.render("Cell");
    if (column.Header) {
      const columnName = column.Header.toLowerCase();
      const columnId = column.id.toLowerCase() || ''
      switch (true) {
        case columnName === "start date":
        case columnName === "end date":
          return renderedCell;
        case columnName === "event link":
          return <LinkCellType value={""} />;
        case columnName === "status":
          return <StatusCellType value={""} />;
        case columnName === "% complete":
        case columnId === "progress__c":
          return <ProgressCompleteCellType value={""} />;
        case columnName.includes("deadline"):
        case columnName.includes("date"):
          return <DateCellType value={""} />;
        case columnName.includes("scholarship name"):
          return <ScholarshipNameCellType value={""} globeHide={globeHide} />;
        case columnId === "number_of_submitted_applications__c" :
          return <PeopleCountCellType value={""} />;
        case columnName.includes("change"):
          return <PercentChangeCellType value={""} />;
        case columnName === "id":
        case columnName === "application":
          return <TagCellType value={""} />;
        case columnName === "score":
          return <ScoreCellType value={""} />;
        case columnName === "last seen":
          // return value.split("T")[0]+ " " + value.match(/\d\d:\d\d/)[0];
          return value ? FormatDate(value, 'MM/dd/yyyy t') : "NA"
        case columnName === 'verified':
          return <StatusCheckBox value={value} />
      }
    }

    return "N/A";
  };
  const handleClickRow = (val) => {

    return clickTableDataView(val)
  }
const handleShowEmptyTableTh = (showSelect, showBlankHeader) => {
  if (showBlankHeader) {
    return true
  } else if (showSelect) {
    return true
  } else {
    return false
  }
}

const showEmptyTableTh = handleShowEmptyTableTh(showSelect, showBlankHeader)

const handleReportDownload = (items) => {
  return items.map((item) => {
    return (
      <button className="CTAGrey" key={item?.reportId} onClick={(e) => item.onClick(e)}>{item?.label}</button>
    );
  });
};

  const renderTableButton = (row, i) => {
    const createdAtDate = new Date(row.original.created_at)
    const currentTime = new Date();
    const timeDifference = currentTime - createdAtDate;
    const twentyFourHours = 24 * 60 * 60 * 1000;
    if (row.original.is_processing && pageType == 'REPORTS' && (timeDifference > twentyFourHours)) {
      return <td className="table__row-menu-cell"> <p className='H4Desktop color__red'><b><i>Failed</i></b></p></td>
    } else if (row.original.is_processing) {
      return <td className="table__row-menu-cell"><p className='H4Desktop color__green'><b><i>Processing...</i></b></p></td>
    } else if (!row.original.url && pageType == 'REPORTS') {
      return <td className="table__row-menu-cell"> <p className='H4Desktop color__red'><b><i>Failed</i></b></p> </td>
    } else if (row.original.url && pageType == 'REPORTS') {
      return <td className="table__row-menu-cell"> <p className='H4Desktop download-button-link'>{handleReportDownload(getRowMenu(row.original))}</p></td>
    } else {
      return <td className="table__row-menu-cell">
        {showRowCta && getRowCta(row.original)}
        {showResetPasswordBtn && pageType == 'PEOPLE' && <button className="donor-people-reset-password" onClick={(e) => handleResetPasswordClick(e, row.original)}>Password Reset</button>}
        {!showRowCta && <DotMenu items={getRowMenu(row.original)} setOpenMenu={setOpenDotMenu} isTable={true} index={i + 1} openMenu={openDotMenu} ensureDotMenuVisible={ensureDotMenuVisible} />}
      </td>
    }
  }
  return (
    <div id={id} ref={tableRef} className={`${pageType == 'APPLICATIONS' ? 'table__container-applications-tab' : 'table__container'}`} >
      {(isLoading) ?
        <>
          <div className="skeleton-loader__container"><TableListingLoader /></div>
          <Loader loading={isLoading} />
        </> 
        :
        <table {...getTableProps()} className = {router.query.scholarshipId && "donor-application-page-table-data"}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                {showSelect && (
                  <th className="table__checkbox-row-header">
                    <label className="option-checkbox">
                      <span className="checkbox__input">
                        <input
                          className="table__checkbox"
                          type="checkbox"
                          onChange={handleAllSelect}
                          checked={removeRowsNewSelect.length ? false : (selectedRowsAllNewSelect === "all" || (selectedRowsAllNewSelect === "all_on_page" && selectedRowsNewSlect.length !== 0) ? true : false)}
                        />
                        <span className="checkbox__control">
                          <FieldCheckbox />
                        </span>
                      </span>
                    </label>

                  </th>
                )}

                {headerGroup.headers.map((column) => (
                  // <th className="EyebrowGrey" {...column.getHeaderProps()}>
                  <th className={column.customClass ? `EyebrowGrey ${column.customClass}` : "EyebrowGrey"} {...column.getHeaderProps()}>
                    {renderColumnHeader(column)}
                  </th>
                ))}

                {showEmptyTableTh && <th className="table__checkbox-row-menu"></th>}
              </tr>
            ))}
          </thead>
          <tbody className="BodyDefaultRegularBlack" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {showSelect && (
                    <td className="table__checkbox-cell">

                      {!row.original.is_processing && <label className="option-checkbox">
                        <span className="checkbox__input">
                          <input
                            className="table__checkbox"
                            type="checkbox"
                            onChange={(e) => handleRowSelect(e, row)}
                            checked={selectedRowsNewSlect.includes(row.original.sfid)}
                          />
                          <span className="checkbox__control">
                            <FieldCheckbox />
                          </span>
                        </span>
                      </label>}


                    </td>
                  )}

                  {row.cells.map((cell, i) => {
                    if (cell.value != null) {
                      if (cell.column.profileView) {
                        return (
                          <td {...cell.getCellProps()}>
                            <div className="applicant-persona-my-donors">
                              <div className="applicant-persona-my-donors__user-badge">
                                <UserBadge
                                  firstName={getCellComponentSplitData(cell.row.original, cell.column.porfileFirstname)}
                                  lastName={getCellComponentSplitData(cell.row.original, cell.column.porfileLastname)}
                                  profileImage={getCellComponentSplitData(cell.row.original, cell.column.profileImage)}
                                />
                              </div>
                              <div className="applicant-persona-my-donors__name-and-email">
                                <div className="applicant-persona-my-donors__name-and-email__name">
                                  {getCellComponent(cell)}
                                </div>
                                <div className="applicant-persona-my-donors__name-and-email__email">
                                  {getCellComponentSplitData(cell.row.original, cell.column.porfileEmail)}
                                </div>
                              </div>
                            </div>

                          </td>
                        );
                      } else {
                        return (
                          <td {...cell.getCellProps()} className = {(cell?.column.Header === "Exit Reason" || (cell?.column.Header ==="Role" && cell?.column.id === "heroku_role__c" && pageType === "PEOPLE" )) && "exit_reason_cell"}>
                            <div className="sponsor-tag-container">
                              <div className={cell.column.tdCustomClass}>
                                  <span className={cell.column.tdCustomClass} onClick={() => handleClickRow(cell.row.original)}>{getCellComponent(cell)}</span>
                              </div>
                            </div>
                          </td>
                        );
                      }
                    } else {
                      return (
                        <td>
                          <span className='date-cell-type'>
                            <span className='date-cell-type__date BodySmallMediumBlack NotApplicable__Date'>
                              {getCellComponentForDataType(cell)}
                            </span>
                          </span>
                        </td>
                      )
                    }
                  })}
                  {renderTableButton(row, i)}
                </tr>
              );
            })}
          </tbody>
        </table>

      }
      {!isLoading && (!tableData || tableData.length === 0) && (
        <NoDataToDisplay
          description={noDataDescription}
          ctaLabel={noDataCtaLabel}
          ctaOnClick={noDataCtaOnClick}
        />
      )}
      <ToastContainer />
    </div>
  );
};

// handle proptypes
Table.propTypes = {
  showBlankHeader: PropTypes.bool,

};

export default Table;